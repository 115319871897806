import { TokenlessToxiHttpClient } from '@/api/toxicology-client';
import { getAccessToken } from '@/authentication/auth0';
import { ModalType, TargetUser } from '@/components/AuthModal.vue';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { useAuth0 } from '@auth0/auth0-vue';
import * as Sentry from '@sentry/vue';
import { useStorage } from '@vueuse/core';
import { AxiosError } from 'axios';
import { ref, watch } from 'vue';

export function useBankidLinking() {
  const BANKID_LINKING_CLIENT_ID = import.meta.env
    .VITE_ABC_BANKID_LINKING_CLIENT_ID;
  const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
  const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
  const BANKID_CONNECTION_NAME = import.meta.env.VITE_BANKID_CONNECTION_NAME;
  const EMAIL_PASSWORD_CONNECTION_NAME = 'Username-Password-Authentication';
  if (
    !BANKID_LINKING_CLIENT_ID ||
    !AUTH0_DOMAIN ||
    !BANKID_CONNECTION_NAME ||
    !AUTH0_AUDIENCE
  ) {
    throw new Error('Missing environment variables for bankid linking.');
  }
  const NOT_PART_REGEX = /user (.*?) is not part of the (.*?) organization/;

  const isBankidLinkingLoading = ref(false);
  const {
    user,
    loginWithRedirect,
    idTokenClaims,
    logout,
    isAuthenticated,
    error,
  } = useAuth0();
  const hasProposedBankidLink = useStorage('hasProposedBankidLink', false);
  const isBankidLinked = ref(true);
  const isPopUpClosed = ref(false);
  // INFO: This is used to determine if the user is coming from an email link,
  // and if so, we should not try to get the organization from them, and it
  // will also determine what to do when a popup is blocked.
  const isEmailFlow = ref(false);
  const authModal = ref<{
    data?: Partial<TargetUser>;
    type: ModalType;
  }>({ type: 'none' });
  const a0 = new Auth0Client({
    // INFO: This client needs to be to an intermediary auth0 application, that requires no organization
    // and has the bankid connection enabled. It also needs to have the correct callback URL:s for
    // the target application, so that the user can be redirected back to the correct page.
    clientId: BANKID_LINKING_CLIENT_ID,
    domain: AUTH0_DOMAIN,
    authorizationParams: {
      audience: AUTH0_AUDIENCE,
    },
  });

  const relog = async (target?: { organizationId: string }) => {
    await logout();
    await loginWithRedirect({
      authorizationParams: {
        organization: target?.organizationId,
        max_age: 0,
      },
    });
  };

  const authenticate = async (connection: string): Promise<string> => {
    await a0.loginWithPopup({
      authorizationParams: {
        connection,
        max_age: 0,
      },
    });
    const newUser = await a0.getUser();
    const newUserId = newUser?.sub;
    if (!newUserId) {
      throw new Error('No user account ID from logged in account.');
    }

    return newUserId;
  };

  const getUserIdsForAuthenticatedUser = async (
    encryptedEmailUser?: string,
  ) => {
    const authenticatedUserId = encryptedEmailUser ?? user.value?.sub;
    if (!authenticatedUserId) {
      throw new Error('No user ID found for email user.');
    }
    const newUserId = await authenticate(BANKID_CONNECTION_NAME);
    return {
      primaryAccountId: authenticatedUserId,
      secondaryAccountId: newUserId,
    };
  };

  const getUserIdsForUnauthenticatedUser = async (bankidUser?: TargetUser) => {
    const bankIdUserId = bankidUser?.userId;
    if (!bankIdUserId) {
      throw new Error('No bankid user ID found.');
    }
    // TODO: Remove after finding bug.
    if (bankIdUserId.startsWith('auth0')) {
      Sentry.captureMessage(
        `Expected to find a BankID user, but an email user was found instead. The userId was ${bankIdUserId}. This means that the pre-existing session might have been used incorrectly, or that the user was somehow logged into two accounts.`,
        {
          tags: {
            method: 'getUserIdsForUnauthenticatedUser',
            isEmailFlow: isEmailFlow.value,
          },
        },
      );
    }
    const newUserId = await authenticate(EMAIL_PASSWORD_CONNECTION_NAME);
    return {
      primaryAccountId: newUserId,
      secondaryAccountId: bankIdUserId,
    };
  };

  const getToken = async (): Promise<string> => {
    const token = isAuthenticated.value
      ? await getAccessToken()
      : await a0.getTokenSilently();

    if (!token) {
      throw new Error('No access token found for user.');
    }
    return token;
  };

  const getAccountIds = async (
    bankidUser?: TargetUser,
    encryptedEmailUser?: string,
  ): Promise<{
    primaryAccountId: string;
    secondaryAccountId: string;
  }> => {
    // INFO: The primary ID should always be the email user, and
    // the secondary ID should always be the bankid user.
    const knownEmailUser = isAuthenticated.value || Boolean(encryptedEmailUser);
    const { primaryAccountId, secondaryAccountId } = knownEmailUser
      ? await getUserIdsForAuthenticatedUser(encryptedEmailUser)
      : await getUserIdsForUnauthenticatedUser(bankidUser);

    if (!primaryAccountId || !secondaryAccountId) {
      throw new Error('No primary or secondary account ID found.');
    }
    return {
      primaryAccountId,
      secondaryAccountId,
    };
  };

  const handleError = (e: unknown, source: 'link' | 'auth') => {
    // INFO: The AxiosErrors are from the backend. And we ourselves
    // determine the error messages.
    if (e instanceof AxiosError) {
      if (e.response?.data.message.includes('user not found')) {
        authModal.value.type = 'user_not_found';
        return;
      }
      if (e.response?.data.message.includes('user update failed')) {
        authModal.value.type = 'update_failed';
        return;
      }
    }
    if (e instanceof Error && 'message' in e) {
      // INFO: If the user closes the pop-up manually, we don't want to
      // annoy them with another pop-up. It is a clear action from their side.
      // The login required and multifactor auth required can come when their
      // session has expired, and that should not trigger a modal, they will
      // get kicked out anyway.
      if (
        e.message === 'Login required' ||
        e.message === 'Multifactor authentication required' ||
        e.message === 'Popup closed'
      ) {
        authModal.value = { type: 'none' };
        isPopUpClosed.value = true;
        return;
      }
      // INFO: UserCancel is when the user has pressed cancel after starting
      // the signing process in their BankID application.
      if (e.message.includes('userCancel')) {
        if (source === 'auth') {
          authModal.value = { type: 'user_cancel_login' };
          return;
        }
        authModal.value = { type: 'user_cancel_link' };
        return;
      }
      // INFO: Collect failed is a BankID error, and can happen for many reasons,
      // most likely some kind of downtime of the BankID API.
      if (e.message.includes('Collect failed')) {
        authModal.value.type = 'linking_error';
        return;
      }
      // INFO: This can happen if the user has already linked their BankID and
      // tries again.
      if (
        e.message.includes('user update failed') ||
        e.message === 'link same user'
      ) {
        authModal.value.type = 'update_failed';
        return;
      }
      if (e.message === 'link has expired') {
        authModal.value.type = 'registration_link_expired';
        return;
      }
      if (e.message === 'link same provider') {
        authModal.value.type = 'linking_no_bankid_found';
        return;
      }
      // INFO: This is telling us that the user is not a part of the organization
      // they are trying to authenticate towards. This can mean two things, either
      // they have just typed in the wrong organization, or they are trying to
      // authenticate with BankID before any link has been setup to their
      // account that actually belongs to the organization that they want to
      // login to.
      if (e.message.match(NOT_PART_REGEX)) {
        const match = e.message.match(NOT_PART_REGEX)!;
        // INFO: As the primary account is always the email, the bankid connection
        // will only be in the user id as long as the user has not linked their bankid.
        const isBankidConnectionUsed =
          match[1].split('|')[1] === BANKID_CONNECTION_NAME;
        authModal.value = {
          data: {
            userId: match[1],
            organizationId: match[2],
          },
          type: isBankidConnectionUsed ? 'unlinked_bankid' : 'not_part_of_org',
        };
        return;
      }
    }
    // INFO: If we don't know what the error is, it can be good to capture it
    // and log it to sentry, so that we can improve the feedback to our users.
    Sentry.captureException(e);
    if (source === 'link') {
      authModal.value.type = 'linking_error';
    } else {
      authModal.value = {
        type: 'unknown',
      };
    }
  };

  const getOrganizationId = (bankidTarget?: TargetUser) => {
    // INFO: In the rare case that the one receiving the registration
    // email link would be on the same machine as the admin that initiated
    // this registration request, it can cause an issue where the access
    // token is fetched from the cookies, and from this token we parse out
    // the organization, which may or may not be the same as the user
    // wants to login with. Hence, knowing that we we're coming from
    // an email when linking, we should ignore the organization. There's
    // no valid case where we can know the orgazation to log them into.
    if (isEmailFlow.value) return undefined;
    return idTokenClaims.value?.org_id ?? bankidTarget?.organizationId;
  };

  const linkBankid = async (
    bankidTarget?: TargetUser,
    encryptedEmailUser?: string,
  ) => {
    try {
      isBankidLinkingLoading.value = true;
      isEmailFlow.value = Boolean(encryptedEmailUser);
      const accountIds = await getAccountIds(bankidTarget, encryptedEmailUser);
      const token = await getToken();

      const { data } = await TokenlessToxiHttpClient.post<string>(
        '/organization-api-client/link-bankid-account',
        accountIds,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (data !== 'successful linking') {
        // TODO: Remove after finding bug.
        if (data !== 'link has expired') {
          Sentry.captureMessage(
            // INFO: Apparently, the data can be an object. The standard success-object is
            // a string, and it's supposed to be the return type for unsuccessful linkings
            // as well, but I'm guessing that's not the case when we get errors thrown.
            `A problem occured while linking BankID: ${JSON.stringify(data)}`,
            {
              level: 'log',
              tags: {
                bankIdUser: bankidTarget?.userId,
                encryptedEmailUser: encryptedEmailUser,
                primaryAccountId: accountIds.primaryAccountId,
                secondaryAccountId: accountIds.secondaryAccountId,
              },
            },
          );
        }
        throw new Error(data);
      }
      const organizationId = getOrganizationId(bankidTarget);
      authModal.value = {
        data: {
          organizationId,
        },
        type: 'linking_success',
      };
    } catch (e) {
      handleError(e, 'link');
    } finally {
      isBankidLinkingLoading.value = false;
    }
  };

  watch(error, (newError) => {
    handleError(newError, 'auth');
  });

  watch(user, async (newUser) => {
    if (!newUser?.connections) return;
    const bankidConnection = newUser.connections.find(
      (it: string) => it === BANKID_CONNECTION_NAME,
    );
    isBankidLinked.value = Boolean(bankidConnection);
    if (!hasProposedBankidLink.value && !isBankidLinked.value) {
      authModal.value = {
        type: 'linking_proposal',
      };
      hasProposedBankidLink.value = true;
    }
  });

  return {
    isBankidLinkingLoading,
    isBankidLinked,
    authModal,
    linkBankid,
    relog,
    isPopUpClosed,
  };
}
