<template>
  <Teleport to="body">
    <div
      class="absolute top-0 z-50 grid h-full w-full place-content-center bg-black bg-opacity-50"
    >
      <div class="max-w-[800px] rounded-xl bg-white p-8">
        {{ errorDisplay }}
        <div class="mt-14 flex flex-wrap justify-between gap-4">
          <FormSubmitButton
            v-if="
              modalContext.type === 'linking_error' ||
              modalContext.type === 'linking_proposal' ||
              modalContext.type === 'timeout' ||
              modalContext.type === 'user_cancel_link' ||
              modalContext.type === 'unlinked_bankid' ||
              modalContext.type === 'user_cancel_login'
            "
            btn-type="outline"
            class="bg-white"
            :label="t('authentication.modal.no')"
            @click="$emit('abort')"
          />
          <FormSubmitButton
            v-else
            btn-type="outline"
            class="bg-white"
            :label="t('authentication.modal.abort')"
            @click="$emit('cancel')"
          />
          <FormSubmitButton
            v-if="
              modalContext.type === 'unlinked_bankid' ||
              modalContext.type === 'linking_error' ||
              modalContext.type === 'linking_proposal' ||
              modalContext.type === 'timeout' ||
              modalContext.type === 'user_cancel_link' ||
              modalContext.type === 'register_with_bankid'
            "
            :label="linkMessage(modalContext.type)"
            :loading="loading"
            @click="
              $emit(
                'link',
                userDetails && {
                  userId: userDetails.userId,
                  organizationId: userDetails.organizationId,
                },
              )
            "
          />
          <FormSubmitButton
            v-else-if="
              modalContext.type === 'linking_success' ||
              modalContext.type === 'update_failed' ||
              modalContext.type === 'linking_no_bankid_found'
            "
            :label="t('authentication.modal.login')"
            :loading="loading"
            @click="
              $emit(
                'relog',
                modalContext.data?.organizationId
                  ? { organizationId: modalContext.data.organizationId }
                  : undefined,
              )
            "
          />
          <FormSubmitButton
            v-else-if="
              modalContext.type === 'user_not_found' ||
              modalContext.type === 'registration_link_expired'
            "
            :label="t('authentication.modal.continue')"
            :loading="loading"
            @click="$emit('cancel')"
          />
          <FormSubmitButton
            v-else-if="
              modalContext.type === 'unknown' ||
              modalContext.type === 'not_part_of_org' ||
              modalContext.type === 'user_cancel_login'
            "
            :label="t('authentication.modal.retry')"
            :loading="loading"
            @click="$emit('relog')"
          />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import FormSubmitButton from '@/views/admin-view/components/FormSubmitButton.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export type ModalType =
  | 'unlinked_bankid'
  | 'not_part_of_org'
  | 'user_not_found'
  | 'linking_success'
  | 'timeout'
  | 'linking_error'
  | 'registration_link_expired'
  | 'user_cancel_link'
  | 'user_cancel_login'
  | 'update_failed'
  | 'linking_proposal'
  | 'linking_no_bankid_found'
  | 'register_with_bankid'
  | 'unknown'
  | 'none';

export type TargetUser = {
  userId: string;
  organizationId: string;
};

const { t } = useI18n();

const props = defineProps<{
  loading?: boolean;
  modalContext: {
    data?: Partial<TargetUser>;
    type: ModalType;
  };
}>();

const userDetails = computed<TargetUser | undefined>(() =>
  props.modalContext.data?.organizationId && props.modalContext.data?.userId
    ? (props.modalContext.data as TargetUser)
    : undefined,
);

const errorDisplay = computed(() =>
  t(`authentication.modal.${props.modalContext.type}`),
);

const linkMessage = (modalType: ModalType) => {
  if (modalType == 'unlinked_bankid') {
    return t('authentication.modal.link');
  }
  if (
    modalType == 'register_with_bankid' ||
    modalType == 'registration_link_expired'
  ) {
    return t('authentication.modal.continue');
  }
  return t('authentication.modal.yes');
};

defineEmits<{
  link: [bankidTarget?: TargetUser];
  relog: [target?: { organizationId: string }];
  retry: [];
  cancel: [];
  abort: [];
}>();
</script>
